import React, { useEffect } from 'react'
import { Link, Routes, Route } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { selectLoggedIn } from '../../slices/userSlice'

import { Container, Box, Tabs, Tab } from '@mui/material'
import Connections from '../HomeTabs/Connections'
import QuickBooksSync from '../HomeTabs/QuickBooksSync'
import JiraSync from '../HomeTabs/JiraSync'

function Home() {
    const loggedIn = useSelector(selectLoggedIn)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(
        function redirectOnLogout() {
            if (!loggedIn) {
                navigate('/login')
            } else if (location.pathname === '/') {
                navigate('/connections')
            }
        },
        [loggedIn, location.pathname, navigate]
    )

    return (
        <Container sx={{ width: '75%' }}>
            <Box>
                <Tabs value={location.pathname} variant="fullWidth">
                    <Tab
                        label="Connections"
                        value="/connections"
                        component={Link}
                        to="/connections"
                    />
                    <Tab
                        label="QuickBooks Sync"
                        value="/quickbooks-sync"
                        component={Link}
                        to="/quickbooks-sync"
                    />
                    <Tab
                        label="Jira Sync"
                        value="/jira-sync"
                        component={Link}
                        to="/jira-sync"
                    />
                </Tabs>
            </Box>
            <Routes>
                <Route path="/connections" Component={Connections} />
                <Route path="/quickbooks-sync" Component={QuickBooksSync} />
                <Route path="/jira-sync" Component={JiraSync} />
            </Routes>
        </Container>
    )
}

export default Home
