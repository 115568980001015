import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { quickbookCallbackAction } from '../../actions/userActions'

function QuickbookOAuth() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = new URLSearchParams(location.search)
    const code = params.get('code') || ''
    const realmId = params.get('realmId') || ''

    useEffect(
        function connectToHubspot() {
            dispatch(quickbookCallbackAction(code, realmId))
            navigate('/connections')
        },
        [dispatch, navigate, code, realmId]
    )

    return <></>
}

export default QuickbookOAuth
