import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Moment from 'moment'
import { io } from 'socket.io-client'
import styles from './HomeTabs.style'
import { logout } from '../../slices/userSlice'
import { Box, Button, Divider, Switch, Typography } from '@mui/material'
import SyncInformation from '../../components/SyncInformation/SyncInformation'
import TimePicker from 'react-time-picker'

import 'react-time-picker/dist/TimePicker.css'
import 'react-clock/dist/Clock.css'

function QuickBooksSync() {
    const [data, setData] = useState([])
    const dispatch = useDispatch()

    let syncTime = localStorage.getItem('quickbooksSyncTime')

    if (syncTime) {
        const hr = parseInt(syncTime.split(':')[0])
        let min = parseInt(syncTime.split(':')[1])
        syncTime = `${new Date(
            new Date().setUTCHours(hr, min)
        ).getHours()}:${new Date(new Date().setUTCHours(hr, min)).getMinutes()}`
    } else {
        syncTime = '00:00'
    }

    let dailySync =
        localStorage.getItem('quickbooksDailySync') === 'true' ? true : false

    const [value, onTimeChange] = useState(syncTime)
    const [isChecked, toggleState] = useState(dailySync)

    const handleLogout = () => {
        dispatch(logout())
    }

    function onToggle(event) {
        toggleState(() => {
            return event.target.checked
        })
    }

    useEffect(() => {
        const socket = io(process.env.REACT_APP_API_URL)
        async function getLastLog() {
            try {
                console.log('Initiated getting last log')
                // Make a POST request to your login API endpoint
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        '/api/sync/integration-log/Quickbook',
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                        },
                    }
                )

                if (!response.ok) {
                    handleLogout()
                }

                if (response.status === 403) {
                    handleLogout()
                }

                const responsJSON = await response.json()

                setData(() => {
                    return {
                        invoicesCreated: responsJSON.created,
                        invoicesUpdated: responsJSON.updated,
                        status: responsJSON.status,
                        message: responsJSON.message,
                        createdAt: Moment(responsJSON.updatedOn).format(
                            'YYYY-MM-DD HH:mm'
                        ),
                        invoicesDeleted: responsJSON.deleted,
                    }
                })
            } catch (error) {
                console.error('Error:', error)
            }
        }

        function updateData(value) {
            const jsonData = JSON.parse(value)
            if (jsonData.integrationName === 'Quickbook') {
                setData(() => {
                    return {
                        invoicesCreated: jsonData.totalAdded,
                        invoicesUpdated: jsonData.totalUpdated,
                        status: jsonData.status,
                        createdAt: Moment(jsonData.createdAt).format(
                            'YYYY-MM-DD HH:mm'
                        ),
                        invoicesDeleted: jsonData.totalDeleted,
                    }
                })
            }
        }

        getLastLog()

        socket.on('events', updateData)

        return () => {
            socket.off('events', updateData)
            socket.close()
        }
        // eslint-disable-next-line
    }, [])

    async function InitiateSync() {
        try {
            // Make a POST request to your login API endpoint
            const response = await fetch(
                process.env.REACT_APP_API_URL + '/api/sync/invoices',
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    },
                }
            )

            if (!response.ok) {
                throw new Error('Login failed')
            }

            if (response.status === 403) {
                handleLogout()
            }

            const responsJSON = await response.json()

            setData(() => {
                return {
                    invoicesCreated: responsJSON.totalAdded,
                    invoicesUpdated: responsJSON.totalUpdated,
                    status: 'Processing',
                    createdAt: Moment(responsJSON.createdAt).format(
                        'YYYY-MM-DD HH:mm'
                    ),
                }
            })

            // console.log('settings effect info');
            // console.log('Sync response: ', data);
        } catch (error) {
            console.error('Error:', error)
            // Handle login error (e.g., show an error message to the user)
        }
    }

    async function saveSyncSettings() {
        try {
            let date = new Date(
                new Date().setHours(value.split(':')[0], value.split(':')[1])
            )

            let data = {
                dailySync: isChecked,
                syncTime: `${date.getUTCHours()}:${
                    date.getUTCMinutes() < 10
                        ? '0' + date.getUTCMinutes()
                        : date.getUTCMinutes()
                }`,
                syncType: 'Quickbook',
            }

            //  Make a POST request to your login API endpoint
            const response = await fetch(
                process.env.REACT_APP_API_URL + '/api/sync/sync-settings',
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                }
            )

            if (!response.ok) {
                throw new Error('Login failed')
            }

            if (response.status === 403) {
                handleLogout()
            }

            if (response.ok) {
                const responsJSON = await response.json()
                localStorage.setItem('quickbooksSyncTime', data.syncTime)
                localStorage.setItem('quickbooksDailySync', data.dailySync)
                alert(responsJSON.message)
            }

            // console.log('settings effect info');
            // console.log('Sync response: ', data);
        } catch (error) {
            console.error('Error:', error)
            // Handle login error (e.g., show an error message to the user)
        }
    }

    return (
        <Box sx={styles.box}>
            <Box sx={styles.wrapper}>
                <Typography variant="h5">Last Sync Information</Typography>
            </Box>
            <SyncInformation syncInformation={{ data }} />

            <Divider
                sx={{
                    width: '100%',
                    background: 'black',
                    marginBottom: '1rem',
                }}
            />

            <Typography variant="h5">Synchronization schedule</Typography>

            <>
                <Box sx={styles.textContainer}>
                    <Typography sx={styles.text}>Daily Sync At:</Typography>
                    <Typography>
                        <TimePicker
                            onChange={onTimeChange}
                            value={value}
                            disableClock={true}
                        />
                    </Typography>
                </Box>

                <Box sx={styles.textContainer}>
                    <Typography sx={styles.text}>Sync Daily:</Typography>
                    <>
                        <Typography>Off</Typography>
                        <Switch onChange={onToggle} checked={isChecked} />
                        <Typography>On</Typography>
                    </>
                </Box>
            </>
            <Button
                sx={styles.button}
                variant="outlined"
                color="primary"
                onClick={saveSyncSettings}
            >
                Save Sync Settings
            </Button>
            <Button
                sx={styles.button}
                variant="outlined"
                color="primary"
                onClick={InitiateSync}
            >
                Initiate a Sync
            </Button>
        </Box>
    )
}

export default QuickBooksSync
