import React, { useState, useEffect } from 'react'
import useChange from '../../customHooks/useChange'
import './QuickBooks.css'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectLoggedIn,
    selectHSConnected,
    selectHSInfo,
    selectQBConnected,
    selectQBInfo,
    selectBSConnected,
    selectJiraConnected,
    selectJiraUsername,
    jiraConnected,
    jiraUpdated,
} from '../../slices/userSlice'

import styles from './HomeTabs.style'
import { styled } from '@mui/material/styles'
import { Box, Button, Divider, Typography } from '@mui/material'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { useNavigate } from 'react-router-dom'
import { verifyJiraCredentials } from '../../actions/userActions'

const HubSpotButton = styled(Button)(() => styles.hubspot)

function Connections() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const loggedIn = useSelector(selectLoggedIn)
    const hsConnected = useSelector(selectHSConnected)
    const hsInfo = useSelector(selectHSInfo)
    const qbConnected = useSelector(selectQBConnected)
    const qbInfo = useSelector(selectQBInfo)
    const bsConnected = useSelector(selectBSConnected)
    const jConnected = useSelector(selectJiraConnected)

    const jiraUsername = useSelector(selectJiraUsername)

    const [editing, setEditing] = useState(false)
    const [jiraCredentials, handleChange, setData] = useChange({
        username: jiraUsername,
        token: jConnected ? '********' : '',
    })

    useEffect(
        function redirectOnLogout() {
            if (!loggedIn) {
                navigate('/login')
            }
        },
        [loggedIn, navigate]
    )

    async function submitJiraCredentials(event) {
        setEditing(false)
        const verified = await verifyJiraCredentials(
            localStorage.getItem('accessToken'),
            jiraCredentials.username,
            jiraCredentials.token,
            jConnected
        )
        if (verified.success) {
            // anonymize token
            setData({ ...jiraCredentials, token: '********' })
            if (!jConnected) {
                dispatch(
                    jiraConnected({
                        jiraAccId: verified.jiraAccId,
                        jiraUsername: jiraCredentials.username,
                    })
                )
            } else {
                dispatch(
                    jiraUpdated({ jiraUsername: jiraCredentials.username })
                )
            }
        } else {
            setEditing(true)
        }
        alert(verified.message)
    }

    function modifyJiraCredentials() {
        // require user to re-enter token if provided before
        setData({ ...jiraCredentials, token: '' })
        setEditing(true)
    }

    function cancelModifyJiraCredentials() {
        if (jConnected) setData({ username: jiraUsername, token: '********' })
        else {
            setData({ username: '', token: '' })
        }
        setEditing(false)
    }

    return (
        <div style={styles.root}>
            <Box sx={styles.box}>
                <Typography sx={styles.typography} variant="h4">
                    QuickBooks Settings
                </Typography>

                {qbConnected && (
                    <>
                        <Typography sx={styles.typography} variant="body1">
                            Connected to:{' '}
                            {`${qbInfo.companyName} (${qbInfo.companyId})`}
                        </Typography>
                    </>
                )}
                {!qbConnected && (
                    <a
                        href={`${process.env.REACT_APP_API_URL}/api/quickbook/install`}
                        className="quickbooks"
                    >
                        {' '}
                    </a>
                )}
            </Box>

            <Box sx={styles.box}>
                <Typography sx={styles.typography} variant="h4">
                    Brightspace Settings
                </Typography>

                {bsConnected && (
                    <>
                        <Typography sx={styles.typography} variant="body1">
                            Connected to Brightspace.{' '}
                            <a
                                href={`${process.env.REACT_APP_API_URL}/api/brightspace/install`}
                                className="brightspace"
                            >
                                {'Refresh Connection'}
                            </a>
                        </Typography>
                    </>
                )}
                {!bsConnected && (
                    <a
                        href={`${process.env.REACT_APP_API_URL}/api/brightspace/install`}
                        className="brightspace"
                    >
                        {'Connect to Brightspace'}
                    </a>
                )}
            </Box>

            <Divider sx={{ width: '100%', background: 'black' }} />

            <Box sx={styles.box}>
                <Typography sx={styles.typography} variant="h4">
                    Jira Settings
                </Typography>

                <ValidatorForm
                    style={styles.form}
                    onSubmit={submitJiraCredentials}
                    instantValidate={false}
                >
                    <TextValidator
                        sx={styles.form_field}
                        disabled={!editing}
                        label="Username"
                        name="username"
                        value={jiraCredentials.username}
                        onChange={handleChange}
                        validators={['required']}
                        errorMessages={['this field is required']}
                    />
                    <TextValidator
                        sx={styles.form_field}
                        disabled={!editing}
                        label="API Token"
                        name="token"
                        value={jiraCredentials.token}
                        onChange={handleChange}
                        type="password"
                        validators={['required']}
                        errorMessages={['this field is required']}
                    />

                    {editing && (
                        <>
                            <Button
                                sx={styles.button}
                                variant="outlined"
                                color="primary"
                                onClick={cancelModifyJiraCredentials}
                            >
                                Cancel
                            </Button>
                            <Button
                                sx={styles.button}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Submit Credentials
                            </Button>
                        </>
                    )}
                </ValidatorForm>

                {!jConnected && !editing && (
                    <Button
                        sx={styles.jira_settings_button}
                        variant="outlined"
                        color="primary"
                        onClick={modifyJiraCredentials}
                    >
                        Add Jira Credentials
                    </Button>
                )}
                {jConnected && !editing && (
                    <Button
                        sx={styles.jira_settings_button}
                        variant="outlined"
                        color="primary"
                        onClick={modifyJiraCredentials}
                    >
                        Edit Jira Credentials
                    </Button>
                )}
            </Box>

            <Divider sx={{ width: '100%', background: 'black' }} />

            <Box sx={styles.box}>
                <Typography sx={styles.typography} variant="h4">
                    HubSpot Settings
                </Typography>

                {hsConnected && (
                    <>
                        <Typography sx={styles.typography} variant="body1">
                            Connected to:{' '}
                            {`${hsInfo.domain} (${hsInfo.portalId})`}
                        </Typography>
                    </>
                )}
                {!hsConnected && (
                    <HubSpotButton
                        href={`${process.env.REACT_APP_API_URL}/api/hubspot/install`}
                    >
                        <span style={{ padding: '0 1rem' }}>
                            Connect to HubSpot
                        </span>
                    </HubSpotButton>
                )}
            </Box>
        </div>
    )
}

export default Connections
