import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './App.css'
import NavBar from './components/NavBar/NavBar'
import Home from './routes/Home/Home'
import Login from './routes/User/Login'
import Signup from './routes/User/Signup'
import HubspotOAuth from './routes/Home/HubspotOAuth'
import QuickbookOAuth from './routes/Home/QuickbookOAuth'
import BrightspaceOAuth from './routes/Home/BrightspaceOAuth'

function App() {
    return (
        <BrowserRouter>
            <NavBar />
            <main>
                <Routes>
                    <Route
                        path="/hubspot/callback/*"
                        Component={HubspotOAuth}
                    />
                    <Route
                        path="/quickbook/callback/*"
                        Component={QuickbookOAuth}
                    />
                    <Route
                        path="/brightspace/callback/*"
                        Component={BrightspaceOAuth}
                    />
                    <Route path="/login" Component={Login} />
                    <Route path="/signup" Component={Signup} />

                    <Route path="*" Component={Home} />
                </Routes>
            </main>
        </BrowserRouter>
    )
}

export default App
