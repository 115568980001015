import React, { useEffect } from 'react'
import useChange from '../../customHooks/useChange'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, selectLoggedIn } from '../../slices/userSlice'
import { signupAction } from '../../actions/userActions'

import styles from './User.styles'
import {
    Box,
    Button,
    Container,
    Link as MaterialLink,
    Typography,
} from '@mui/material'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Link } from 'react-router-dom'

function Signup() {
    const navigate = useNavigate()
    const loggedIn = useSelector(selectLoggedIn)
    const dispatch = useDispatch()

    useEffect(
        function redirectToHome() {
            if (loggedIn) {
                dispatch(logout())
                navigate('/login')
            }
        },
        [loggedIn, navigate, dispatch]
    )

    const [potentialUser, handleChange] = useChange({
        email: '',
        password: '',
        confirmPassword: '',
    })

    useEffect(function passwordMatchRule() {
        ValidatorForm.addValidationRule('isPasswordMatch', (val) => {
            const { password } = potentialUser
            return password === val
        })
        return () => ValidatorForm.removeValidationRule('isPasswordMatch')
    })

    function onSubmit(event) {
        event.preventDefault()
        dispatch(signupAction(potentialUser))
    }

    return (
        <Container sx={styles.root_2}>
            <Box style={styles.box}>
                <Typography variant="h4">Create an account</Typography>
            </Box>

            <ValidatorForm
                style={styles.form}
                onSubmit={onSubmit}
                instantValidate={false}
            >
                <TextValidator
                    label="Email"
                    onChange={handleChange}
                    name="email"
                    placeholder="john.doe@gmail.com"
                    value={potentialUser.email}
                    fullWidth
                    validators={['required', 'isEmail']}
                    errorMessages={[
                        'This field is required.',
                        'Email is not valid.',
                    ]}
                />
                <TextValidator
                    label="Password"
                    onChange={handleChange}
                    name="password"
                    value={potentialUser.password}
                    type="password"
                    fullWidth
                    validators={['required', 'minStringLength:8']}
                    errorMessages={[
                        'This field is required.',
                        'Password must be at least 8 characters long.',
                    ]}
                />
                <TextValidator
                    label="Confirm Password"
                    onChange={handleChange}
                    name="confirmPassword"
                    value={potentialUser.confirmPassword}
                    type="password"
                    fullWidth
                    validators={['isPasswordMatch']}
                    errorMessages={['Passwords do not match.']}
                />

                <Button
                    sx={styles.button}
                    variant="outlined"
                    color="primary"
                    type="submit"
                >
                    Create My Account
                </Button>
            </ValidatorForm>

            <MaterialLink sx={styles.link} component={Link} to="/login">
                Already have an account? Sign in
            </MaterialLink>
        </Container>
    )
}

export default Signup
