import React, { memo } from 'react'

import styles from './SyncInformation.styles'
import { Box, Typography } from '@mui/material'

function SyncInformation({ syncInformation }) {
    const {
        createdAt,
        invoicesCreated,
        invoicesUpdated,
        issuesCreated,
        issuesUpdated,
        status,
        message,
        invoicesDeleted,
    } = syncInformation.data

    return (
        <>
            <Box sx={styles.textContainer}>
                <Typography sx={styles.text} variant="body1">
                    Sync Time:
                </Typography>
                <Typography variant="body2">
                    {createdAt === 'Invalid date' ? 'N/A' : createdAt || 'N/A'}
                </Typography>
            </Box>

            <Box sx={styles.textContainer}>
                <Typography sx={styles.text} variant="body1">
                    Status:
                </Typography>
                <Typography variant="body2">{status}</Typography>
            </Box>

            {(status === 'Success' ||
                status === 'In Progress' ||
                status === 'No records found') && (
                <>
                    {invoicesCreated != null && (
                        <Box sx={styles.textContainer}>
                            <Typography sx={styles.text} variant="body1">
                                Invoices Created:
                            </Typography>
                            <Typography variant="body2">
                                {invoicesCreated != null
                                    ? invoicesCreated
                                    : 'N/A'}
                            </Typography>
                        </Box>
                    )}
                    {invoicesUpdated != null && (
                        <Box sx={styles.textContainer}>
                            <Typography sx={styles.text} variant="body1">
                                Invoices Updated:
                            </Typography>
                            <Typography variant="body2">
                                {invoicesUpdated != null
                                    ? invoicesUpdated
                                    : 'N/A'}
                            </Typography>
                        </Box>
                    )}
                    {invoicesDeleted != null && (
                        <Box sx={styles.textContainer}>
                            <Typography sx={styles.text} variant="body1">
                                Invoices Deleted:
                            </Typography>
                            <Typography variant="body2">
                                {invoicesDeleted != null
                                    ? invoicesDeleted
                                    : 'N/A'}
                            </Typography>
                        </Box>
                    )}
                    {issuesCreated != null && (
                        <Box sx={styles.textContainer}>
                            <Typography sx={styles.text} variant="body1">
                                Issues Created:
                            </Typography>
                            <Typography variant="body2">
                                {issuesCreated != null ? issuesCreated : 'N/A'}
                            </Typography>
                        </Box>
                    )}
                    {issuesUpdated != null && (
                        <Box sx={styles.textContainer}>
                            <Typography sx={styles.text} variant="body1">
                                Issues Updated:
                            </Typography>
                            <Typography variant="body2">
                                {issuesUpdated != null ? issuesUpdated : 'N/A'}
                            </Typography>
                        </Box>
                    )}
                </>
            )}
            {status === 'Error' && (
                <>
                    <Box sx={styles.textContainer}>
                        <Typography sx={styles.text} variant="body1">
                            Reason:
                        </Typography>
                        <Typography variant="body2">
                            {message != null ? message : 'N/A'}
                        </Typography>
                    </Box>
                </>
            )}
        </>
    )
}

SyncInformation.defaultProps = {
    syncInformation: {},
}

export default memo(SyncInformation)
