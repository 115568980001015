const styles = {
    textContainer: {
        margin: '1rem 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    text: {
        width: '50%',
        marginRight: '12px',
    },
}

export default styles
