import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'

import { Provider } from 'react-redux'
import store from './store'

const theme = createTheme({
    palette: {
        primary: {
            main: '#1683fb',
        },
    },
    components: {
        MuiTableCell: {
            defaultProps: {
                align: 'center',
            },
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            </Provider>
        </ThemeProvider>
    </StyledEngineProvider>
)
