import {
    login,
    signup,
    hubspotConnected,
    quickbookConnected,
    brightspaceConnected,
} from '../slices/userSlice'
import { getAccessToken, storeInLocalStorage } from './tokenActions'

export async function verifySession(refreshToken, userId) {
    const response = await fetch(
        process.env.REACT_APP_API_URL + '/api/appaccount/verify-session',
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ refreshToken, userId }),
        }
    )

    const result = await response.json()
    return { ...result }
}

export function loginAction({ email, password }) {
    return async function (dispatch) {
        var response, result
        try {
            response = await fetch(
                process.env.REACT_APP_API_URL + '/api/appaccount/signin',
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email, password }),
                }
            )

            result = await response.json()
        } catch (error) {
            result = {
                status: 'failed',
                message: error,
            }
        }

        if (result.status === 'success') {
            storeInLocalStorage({
                accessToken: result.accessToken,
                expiresIn: result.expiresIn,
                refreshToken: result.refreshToken,
                userId: result.userId,
                quickbooksDailySync: result.quickbooksDailySync,
                quickbooksSyncTime: result.quickbooksSyncTime,
                jiraDailySync: result.jiraDailySync,
                jiraSyncTime: result.jiraSyncTime,
            })
            dispatch(
                login({
                    accessToken: result.accessToken,
                    refreshToken: result.refreshToken,
                    userId: result.userId,
                    hubspotAccId: result.hubspotAccId,
                    hubspotInfo: result.hubspotInfo,
                    quickbookAccId: result.quickbookAccId,
                    brightspaceAccId: result.brightspaceAccId,
                    quickbooksInfo: result.quickbooksInfo,
                    jiraAccId: result.jiraAccId,
                    jiraUsername: result.jiraUsername,
                })
            )
        } else {
            alert(`${result.message}`)
        }
    }
}

export function signupAction(user) {
    const { email, password } = user

    return async function (dispatch) {
        const response = await fetch(
            process.env.REACT_APP_API_URL + '/api/appaccount/signup',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
            }
        )

        const result = await response.json()
        if (result.status === 'success') {
            // successfully signed up, store session token
            storeInLocalStorage({
                accessToken: result.accessToken,
                expiresIn: result.expiresIn,
                refreshToken: result.refreshToken,
                userId: result.userId,
            })
            dispatch(
                signup({
                    accessToken: result.accessToken,
                    refreshToken: result.refreshToken,
                    userId: result.userId,
                })
            )
        } else {
            alert(`${result.message}`)
        }
    }
}

export function hubspotCallbackAction(code) {
    return async function (dispatch) {
        const accessToken = await getAccessToken(dispatch)
        const response = await fetch(
            process.env.REACT_APP_API_URL + '/api/hubspot/callback',
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code }),
            }
        )
        const result = await response.json()

        if (response.status === 200) {
            const { hubspotAccId, hubspotInfo } = result
            dispatch(hubspotConnected({ hubspotAccId, hubspotInfo }))
        }
    }
}

export function quickbookCallbackAction(code, realmId) {
    return async function (dispatch) {
        const accessToken = await getAccessToken(dispatch)
        const response = await fetch(
            process.env.REACT_APP_API_URL + '/api/quickbook/callback',
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code, realmId }),
            }
        )
        const result = await response.json()

        if (response.status === 200) {
            const { quickbookAccId, quickbooksInfo } = result
            dispatch(quickbookConnected({ quickbookAccId, quickbooksInfo }))
        }
    }
}

export function brightspaceCallbackAction(code) {
    return async function (dispatch) {
        const accessToken = await getAccessToken(dispatch)
        const response = await fetch(
            process.env.REACT_APP_API_URL + '/api/brightspace/callback',
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code }),
            }
        )
        const result = await response.json()

        if (response.status === 200) {
            const { data } = result
            if (data)
                dispatch(
                    brightspaceConnected({
                        brightspaceAccId: data.brightspaceAccId,
                    })
                )
        }
    }
}

export async function verifyJiraCredentials(
    accessToken,
    username,
    authToken,
    jConnected
) {
    let response
    const options = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, authToken }),
    }

    if (!jConnected) {
        response = await fetch(
            process.env.REACT_APP_API_URL + '/api/jira/create',
            options
        )
    } else {
        response = await fetch(
            process.env.REACT_APP_API_URL + '/api/jira/update',
            options
        )
    }

    const result = await response.json()
    if (response.status === 200) {
        return {
            success: true,
            message: result.message,
            jiraAccId: result.jiraAccId,
        }
    }
    return { success: false, message: result.message }
}
