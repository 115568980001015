const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '2rem auto',
    },
    box: {
        margin: '2rem',
        width: '100%',
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    textContainer: {
        margin: '1rem 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    text: {
        width: '50%',
        marginRight: '12px',
    },
    typography: {
        marginBottom: '1rem',
    },
    button: {
        width: '45%',
        margin: '2rem auto 1rem 1rem',
        borderRadius: '0',
    },
    jira_settings_button: {
        width: '100%',
        margin: '1rem auto',
        borderRadius: '0',
    },
    quickbooks: {
        textTransform: 'none',
        color: 'white',
        background: '#30a41c',
        '&:hover': {
            background: '#20940c',
        },
    },
    hubspot: {
        textTransform: 'none',
        color: 'white',
        background: '#ff5c35',
        '&:hover': {
            background: '#e65330',
        },
    },
    form: {
        width: '100%',
        padding: '1rem',
    },
    form_field: {
        width: '95%',
        margin: '1rem 0 0',
    },
}

export default styles
