import React, { useEffect } from 'react'
import useChange from '../../customHooks/useChange'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectLoggedIn } from '../../slices/userSlice'
import { loginAction } from '../../actions/userActions.js'

import styles from './User.styles.js'
import { Box, Button, Link as MaterialLink, Typography } from '@mui/material'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Link } from 'react-router-dom'

function Login() {
    const navigate = useNavigate()

    const loggedIn = useSelector(selectLoggedIn)
    const dispatch = useDispatch()

    const [potentialUser, handleChange] = useChange({
        email: '',
        password: '',
    })

    useEffect(
        function redirectToHome() {
            if (loggedIn) {
                navigate('/connections')
            }
        },
        [loggedIn, navigate]
    )

    function onSubmit(event) {
        event.preventDefault()
        const { email, password } = potentialUser
        dispatch(loginAction({ email, password }))
    }

    return (
        <div style={styles.root}>
            <Box sx={styles.box}>
                <Typography variant="h4">Login</Typography>
                <Typography variant="subtitle1">
                    Welcome to HubSpot Sync for Intelligent Audit
                </Typography>
            </Box>

            <ValidatorForm
                style={styles.form}
                onSubmit={onSubmit}
                instantValidate={false}
            >
                <TextValidator
                    sx={styles.form_field}
                    label="Email"
                    name="email"
                    value={potentialUser.email}
                    onChange={handleChange}
                    validators={['required', 'isEmail']}
                    errorMessages={[
                        'this field is required',
                        'email is not valid',
                    ]}
                />
                <TextValidator
                    sx={styles.form_field}
                    label="Password"
                    name="password"
                    value={potentialUser.password}
                    onChange={handleChange}
                    type="password"
                    validators={['required']}
                    errorMessages={['this field is required']}
                />
                <Button
                    sx={styles.button}
                    variant="outlined"
                    color="primary"
                    type="submit"
                >
                    Login
                </Button>
            </ValidatorForm>

            <MaterialLink sx={styles.link} component={Link} to="/signup">
                Create an account
            </MaterialLink>
        </div>
    )
}

export default Login
