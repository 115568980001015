import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { brightspaceCallbackAction } from '../../actions/userActions'

function BrightspaceOAuth() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = new URLSearchParams(location.search)
    const code = params.get('code') || ''

    useEffect(
        function connectToBrightspace() {
            dispatch(brightspaceCallbackAction(code))
            navigate('/connections')
        },
        [dispatch, navigate, code]
    )

    return <></>
}

export default BrightspaceOAuth
