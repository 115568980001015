import { createSlice } from '@reduxjs/toolkit'
import { verifySession } from '../actions/userActions'

var refreshToken = localStorage.getItem('refreshToken')
var accessToken = localStorage.getItem('accessToken')
var userId = localStorage.getItem('userId')
var hubspotAccId,
    hubspotInfo,
    quickbookAccId,
    brightspaceAccId,
    quickbooksInfo,
    jiraAccId,
    jiraUsername

if (refreshToken) {
    const verification = await verifySession(refreshToken, userId)
    if (!verification.isValid) {
        refreshToken = ''
        accessToken = ''
        userId = ''
        hubspotAccId = ''
        hubspotInfo = {}
        quickbookAccId = ''
        brightspaceAccId = ''
        quickbooksInfo = {}
        jiraAccId = ''
        jiraUsername = ''
    } else {
        hubspotAccId = verification.hubspotAccId
        hubspotInfo = verification.hubspotInfo
        quickbookAccId = verification.quickbookAccId
        brightspaceAccId = verification.brightspaceAccId
        quickbooksInfo = verification.quickbooksInfo
        jiraAccId = verification.jiraAccId
        jiraUsername = verification.jiraUsername
    }
}

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        accessToken,
        refreshToken,
        userId,
        hubspotAccId,
        hubspotInfo,
        quickbookAccId,
        brightspaceAccId,
        quickbooksInfo,
        jiraAccId,
        jiraUsername,
    },
    reducers: {
        login: (state, action) => {
            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
            state.userId = action.payload.userId
            state.hubspotAccId = action.payload.hubspotAccId
            state.hubspotInfo = action.payload.hubspotInfo
            state.quickbookAccId = action.payload.quickbookAccId
            state.quickbooksInfo = action.payload.quickbooksInfo
            state.brightspaceAccId = action.payload.brightspaceAccId
            state.jiraAccId = action.payload.jiraAccId
            state.jiraUsername = action.payload.jiraUsername
        },
        signup: (state, action) => {
            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
            state.userId = action.payload.userId
        },
        logout: (state) => {
            state.accessToken = ''
            state.refreshToken = ''
            state.userId = ''
            state.hubspotAccId = ''
            state.hubspotInfo = {}
            state.quickbookAccId = ''
            state.quickbooksInfo = {}
            state.brightspaceAccId = ''
            state.jiraAccId = ''
            state.jiraUsername = ''
            localStorage.clear()
        },
        tokensReceived: (state, action) => {
            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
        },
        hubspotConnected: (state, action) => {
            state.hubspotAccId = action.payload.hubspotAccId
            state.hubspotInfo = action.payload.hubspotInfo
        },
        quickbookConnected: (state, action) => {
            state.quickbookAccId = action.payload.quickbookAccId
            state.quickbooksInfo = action.payload.quickbooksInfo
        },
        brightspaceConnected: (state, action) => {
            state.brightspaceAccId = action.payload.brightspaceAccId
        },
        jiraConnected: (state, action) => {
            state.jiraAccId = action.payload.jiraAccId
            state.jiraUsername = action.payload.jiraUsername
        },
        jiraUpdated: (state, action) => {
            state.jiraUsername = action.payload.jiraUsername
        },
    },
})

export const selectLoggedIn = (state) =>
    state.user.refreshToken ? true : false
export const selectHSConnected = (state) =>
    state.user.hubspotAccId ? true : false
export const selectHSInfo = (state) => state.user.hubspotInfo
export const selectQBConnected = (state) =>
    state.user.quickbookAccId ? true : false
export const selectQBInfo = (state) => state.user.quickbooksInfo
export const selectBSConnected = (state) =>
    state.user.brightspaceAccId ? true : false
export const selectJiraConnected = (state) =>
    state.user.jiraAccId ? true : false
export const selectJiraUsername = (state) =>
    state.user.jiraUsername ? state.user.jiraUsername : ''

export const {
    login,
    signup,
    logout,
    tokensReceived,
    hubspotConnected,
    quickbookConnected,
    brightspaceConnected,
    jiraConnected,
    jiraUpdated,
} = userSlice.actions

export default userSlice.reducer
